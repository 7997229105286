.educationHolder {
    margin-top: 2.5vh;
    display: flex;
    flex-direction: column;
    font-family: "Ubuntu";
    align-items: center;
    justify-content: center;
}

.educationHeader {
    font-size: 5rem;
    color: var(--theme-color-5);
    width: 75vw;
    border-bottom: 1px solid var(--theme-color-4);
    text-shadow: 0px 20px 30px rgb(38, 57, 77);
}

.educationContent {
    display: flex;
    flex-direction: row;
    margin: 2rem auto;
    padding: 0;
}

.educationCard {
    display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 0rem 2rem 0rem 2rem;
  border: 1px solid var(--theme-color-5);
  border-radius: 1rem;
  max-height: 60vh;
  height: 60vh;
  width: 20vw;
  min-width:350px;
}

.educationCardHeader {
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: var(--theme-color-5);
    background-color: var(--theme-color-1);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.educationImage {
    border: 1px solid var(--theme-color-5);
}

.educationInfo {

}

.educationTitle {

}

.educationSubtitle {

}

.educationBody {
    background-color: var(--theme-color-4);
    height: 100%;
    overflow-y: scroll;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.educationDescription {
    padding: 1rem 1.5rem 1rem 1.5rem;
  color: var(--theme-color-1);
}

.degreeButton {
    color: var(--theme-color-1);
    width: 100%;
    text-align: center;
    transition: all 500ms ease;
}

.degreeButton:hover {
    cursor: pointer;
    color: var(--theme-color-2);
}

@media screen and (max-width: 1650px) {
    .educationHolder {
      max-width: 100vw;
      margin-top: 0;
      height: 100%;
      margin-top: 0;
     overflow: hidden;
     position: relative;
    }
  
    .educationHeader {
      top: 4rem;
      font-size: 3rem;
      left: 0;
      right: 0;
      margin-left: auto;
    margin-right: auto;
      position: absolute;
      max-width: 75vw;
      text-align: center;
    }
  
    .educationContent {
        position: absolute;
        top: 8rem;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      padding-left: 45%;
    padding-right: 45%;
      overflow: auto;
      max-width: 100vw;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
   
    }

    .educationContent:after {
      content: "";
      padding-right: 100%;
    }
  
    .educationCard {
        height: 75vh;
      max-height: 70vh;
      scroll-snap-align: center;
      flex-shrink: 0;
      transform-origin: center center;
      transform: scale(1);
      transition: transform 0.5s;
      position: relative;
    }
  
    .educationCardHeader {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  
    .educationBody {
      max-height: 65vh;
    }
  }

  @media screen and (max-width: 900px) {
    .educationHeader {
      top: 3rem;
      border-bottom: none;
    }
  
    .educationContent {
        top: 6.5rem;
        transition: all 500ms ease;
    }

    
  .wiggle {
    animation-name: shake;
    -webkit-animation-name: shake;
    -ms-animation-name: shake;
    animation-duration: 750ms;
    -ms-animation-duration: 750ms;
    -webkit-animation-duration: 750ms;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    animation-delay: 1s;
    -ms-animation-delay: 1s;
    -webkit-animation-delay: 1s;
  }

  @keyframes shake {
    10% {
      transform: translate3d(0px, 0, 0);
    }

    50% {
      transform: translate3d(-100px, 0, 0);
    }
  
    90% {
      transform: translate3d(0px, 0, 0);
    }
  }
    
  }