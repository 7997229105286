:root {
  --theme-color-1: #003049;
  --theme-color-2: #d62828;
  --theme-color-3: #f77f00;
  --theme-color-4: #fcbf49;
  --theme-color-5: #eae2b7;
}

.skillsHolder {
  
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu", sans-serif;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.skillsHeader {
  font-size: 5rem;
  color: var(--theme-color-5);
  width: 75vw;
  border-bottom: 1px solid var(--theme-color-4);
  text-shadow: 0px 20px 30px rgb(38, 57, 77);
}

.skills {
  display: flex;
  flex-direction: row;
}

.skillSet, .skillsWiggle {
  position: relative;
  margin: 2rem;
  /* padding: 0 0rem 3rem 0rem; */
  border: 1px solid var(--theme-color-5);
  background-color: var(--theme-color-4);
  color: var(--theme-color-1);
  border-radius: 1rem;
  width: 14rem;
  height: 30rem;
  /* max-width: 13rem; */
  max-height: 30rem;
  overflow: hidden;
}

.skillsTitle {
  position: sticky;
  font-size: 20px;
  padding: 1rem 3rem 0rem 3rem;
  border-bottom: 1px solid var(--theme-color-5);
  background-color: var(--theme-color-1);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  text-align: center;
  color: var(--theme-color-3);
}

.skillList {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 10rem;
}

.skillListItem {
  margin: 1rem;
  border-bottom: 1px solid var(--theme-color-1);
}

.skill {
  display: flex;
  flex-direction: row;
  transition: all 500ms ease;
}

.skill:hover {
  cursor: pointer;
  color: var(--theme-color-2);
}

.skillIcon {
  width: 3rem;
  height: 3rem;
  transition: all 500ms ease;
}

/* .skill:hover .skillIcon {
  width: 4rem;
  cursor: pointer;
  color: var(--theme-color-5);
} */

.skillName {
  margin-left: 1.5rem;
  line-height: 3rem;
  transition: all 500ms ease;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 
.skill:hover .skillName {
  line-height: 4rem;
  color: var(--theme-color-2);
} */



@media screen and (max-width: 1650px) {
  .skillsHolder {
    max-width: 100vw;
    
    height: 100%;
   overflow: hidden;
   position: relative;
  }

  .skillsHeader {
    top: 7rem;
    left: 0;
    right: 0;
    font-size: 3rem;
    margin-left: auto;
  margin-right: auto;
    position: absolute;
    max-width: 75vw;
    text-align: center;
  }

  .skills {
      position: absolute;
      top: 11rem;
    height: 100%;
    width: 100%;   
    padding-left: 45%;
    padding-right: 45%;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    
  }

  .skills:after {
    content: "";
    padding-right: 100%;
  }


  .skillSet, .skillsWiggle {
    scroll-snap-align: center;
    flex-shrink: 0;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
  }
}

@media screen and (max-width: 900px) {
  .skillsHeader {
    top: 3rem;
    border-bottom: none;
  }

  .skills {
      top: 7rem;
  }

  .skillsWiggle {
    animation-name: shake;
    -webkit-animation-name: shake;
    -ms-animation-name: shake;
    animation-duration: 750ms;
    -ms-animation-duration: 750ms;
    -webkit-animation-duration: 750ms;
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -ms-animation-iteration-count: 2;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    animation-delay: 1s;
    -ms-animation-delay: 1s;
    -webkit-animation-delay: 1s;
  }
  
  @-webkit-keyframes wiggle {
    0% {-webkit-transform: rotate(10deg);}
    25% {-webkit-transform: rotate(-10deg);}
    50% {-webkit-transform: rotate(20deg);}
    75% {-webkit-transform: rotate(-5deg);}
    100% {-webkit-transform: rotate(0deg);}
  }
  
  @-ms-keyframes wiggle {
    0% {-ms-transform: rotate(1deg);}
    25% {-ms-transform: rotate(-1deg);}
    50% {-ms-transform: rotate(1.5deg);}
    75% {-ms-transform: rotate(-5deg);}
    100% {-ms-transform: rotate(0deg);}
  }
  
  @keyframes wiggle {
    0% {transform: rotate(10deg);}
    25% {transform: rotate(-10deg);}
    50% {transform: rotate(20deg);}
    75% {transform: rotate(-5deg);}
    100% {transform: rotate(0deg);}
  }

  @keyframes shake {
    0% {
      transform: translate3d(-100px, 0, 0);
    }

    50% {
      transform: translate3d(-200px, 0, 0);
    }
  
    100% {
      transform: translate3d(-100px, 0, 0);
    }
  }
}