.AboutMe {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.aboutHolder {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    scroll-behavior: auto;
    /* overflow-x: hidden; */

    
}

