:root {
  --theme-color-1: #003049;
  --theme-color-2: #D62828;
  --theme-color-3: #F77F00;
  --theme-color-4: #FCBF49;
  --theme-color-5: #EAE2B7;
  --portfolio-mobile-point: 1080px;
}

.stack-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
  border-style: none !important;
}

.stack-body {
  padding: 0px !important;
}

.stack-toggle {
  white-space: nowrap;
}

.stack-title {
  padding: 0px !important;
}

.no-wrap {
  white-space: nowrap;
  text-decoration: none;
}

.text-align-center {
  text-align: center;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.skills-list {
  white-space: nowrap;
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  justify-content: center;
  text-align: center;
}

.skills-container {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}



.no-border {
  border: none !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

@media only screen and (max-width: 600px) {
  .collapse {
    display: none;
  }

  .show {
    display: block !important;
  }
}

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

ul li {
  padding-left: 1em;
  text-indent: -1em;
}

#bg {
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.social-row {
  height: 30px !important;
  width: 30px !important;
  margin: 2px;
  color: white;
}

.nav-item-link {
  color: gainsboro !important;
  cursor: pointer;
  padding-left: 0.5rem !important;
}

.navbar {
  padding: 0rem 0.125rem !important;
}

.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  cursor: pointer;
}

.cursor-point {
  cursor: pointer;
}

.skill-card-col {
  flex-grow: 0 !important;
}

.section-header {
  text-align: center !important;
}

.email-color {
  background-color: #212529;
}

.white-text {
  color: white;
}

.close {
  color: white !important;
}

.email-modal {
  background-color: gainsboro;
}

.experience-card {
  padding: 0rem 2rem 2rem 2rem;
  overflow: hidden;
}

.projects-card {
  padding: 0rem 2rem 2rem 2rem;
}



.card-bottom {
  margin-bottom: 5rem;
}

.read-more-button {
  color: blue !important;
}

.bg-gainsboro {
  background-color: gainsboro;
}

.container {
  scroll-snap-type: y mandatory !important;
}

.child {
scroll-snap-align: start !important;
}

