/* The progress container (grey background) */
.progressContainer {
    position: relative;
    width: 100%;
    height: 8px;
    background: var(--theme-color-1);
  }
  
  /* The progress bar (scroll indicator) */
  .progressBar {
    width: 0px;
    height: 8px;
    background: var(--theme-color-5);
    transition: width 250ms ease-in-out;
  }