html {
    overscroll-behavior: none;
background-color: var(--theme-color-1);
}

.app-container {
    overscroll-behavior: none;
    overflow:hidden;
}

.scrollpointcenter {
  scroll-snap-align: center !important;
}

.scrollpointstart {
    scroll-snap-align: start !important;
  }

.top-content {
    height: 100vh;
}

.bottom-content {
    height: 100vh;
    overscroll-behavior: none;
    
}

.middle-content {
    height: 100vh;
  
    
}

.content-card {
    height: 70vh;
    display: grid;
    margin: 0;
    place-items: center center;
}

.card-col {
    max-width: 500px !important;
    display: grid;
    margin: 0;
    place-items: center center;
}

.card-header {
    width: 100%;
    height: auto;
    
}
  
.scrollcontainer { 
    width: 100%;
    scroll-snap-type: y mandatory;
    flex-basis: 50%;
    scroll-snap-stop: always;
    overflow-y: auto;
    overscroll-behavior: none;
  }

  @media screen and (max-width: 500px) {
    .scrollcontainer { 
        max-height: 85vh;
      }
  }

.dev-icon-space {
    margin-right: 10px !important;
}

.label-space {
    margin-left: 10px !important;
}

.scroll {
    
    width: 100%;

    overflow-y: scroll;
    
}

.scroll-card-container {
    display: flex;
}

.experience-nav {
    max-width:fit-content;
    place-items: center center;
}
