.introductionHolder {
    margin-top: 2.5vh;

    display: flex;
    flex-direction: column;
    font-family: "Ubuntu";
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 90vh;
}

.introductionCard {

   
    
  }
  
  .introHolder {
    border: 1px solid var(--theme-color-5);
    padding: 2rem;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    background-color: #003049;
    max-width: 80vw;
  }
  
  .introImage {
    border: 1px solid var(--theme-color-4);
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    transition: all 500ms ease;
    width: 20rem;
    border-radius: 50%;;
  }
  
  .introImage:hover {
    border: 1px solid var(--theme-color-2);
  }

  .introInfo {
    padding: 2rem;
}

  .introName {
    font-size: 50px;
  }

  .socialRow {
      display: none;
  }

 
  
  .introText {
    
    font-size: 25px;
    color: var(--theme-color-5);
    text-shadow: 0px 20px 30px rgb(38, 57, 77);
  }
  
  .introRoles {
    font-size: 20px;
    color: var(--theme-color-4);
  }
  


  @media screen and (max-width: 900px) {
    
    .introductionHolder {
      align-items:baseline;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }
    
    .introHolder {
      margin-top: 5rem;
      flex-direction: column;
      padding: .25rem;
      max-width: 90vw;
    }

    .introImage {
        width: 17rem;
        margin-bottom: .75rem;
    }

    .introInfo {
        padding: .5rem;
        max-width: 90vw;
        text-align: center;
        border-top: 1px solid var(--theme-color-5);
    }

    .introName {
        
    }

    .socialRow {
        display: flex;
        justify-content: center;
        margin-bottom: .75rem;
    }

    .introText {
        font-size: 20px;
    }

    .introRoles {
        font-size: 15px;
        padding-top: .5rem;
    }

    .introName {
        font-size: 30px;
    }

    
  }