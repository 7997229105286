.modal {
    display: none; /* Hidden by default */
  }

  .modalShow {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }

  @-webkit-keyframes fadeInDown {
    0% {
       opacity: 0;
       -webkit-transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateY(0);
    }
 }
 
 @keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }
  
  /* Modal Content/Box */
  .modalContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      
    background-color: #fefefe;
    margin: 25px auto; /* 15% from the top and centered */
    border: 1px solid #888;
    width: 45rem;
    height: 90%;
    max-height: 65vh;
    border: 1px solid var(--theme-color-5);
    border-radius: 1rem;
    overflow: hidden;
  }

  .modalHeader {
      border-bottom: 1px solid var(--theme-color-1);
      width: 100%;
      background-color: var(--theme-color-1);
      color: var(--theme-color-4);
      font-size: 2rem;
      padding: 1rem;
  }

  .modalBody {
      background-color: var(--theme-color-4);
  }

  .modalFooter {
      display: flex;
      align-items: center;
      justify-content: center;
    border: 1px solid var(--theme-color-3);
    background-color: var(--theme-color-1);
    width: 100%;
    height: 100%;
}

.modalButton {
    margin: 1rem;
    padding: 1rem;
    border: 1px solid var(--theme-color-5);
    border-radius: 1rem;
    transition: all 500ms ease;
    font-size: 18px;
}

.modalButton:hover {
    border: 1px solid var(--theme-color-1);
    color: var(--theme-color-2);
    font-size: 20px;
}


  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  @media screen and (max-width: 900px) {

    .modalContent {
      max-height: 80vh;
      max-width: 95vw;
    }
  }