

.portfolioNavbar {
    background-color: var(--theme-color-1);
    color: var(--theme-color-5);
    border-bottom: 1px solid var(--theme-color-5);
    font-family: 'Ubuntu';
    height: 50px;
    overflow: hidden;
    position: relative;
    max-width: 100%;
}

.navbarBrand {
    display: flex !important;
    flex-direction: row !important;
}

.navbarTitle { 
    color: var(--theme-color-5);
    font-size: larger;
    line-height: 3rem;
}

.navItem {
    margin-left: 1rem;
    color: var(--theme-color-4);
    transition: all 500ms ease;
    line-height: 3rem;
}

.endNavItem {
    margin-left: auto;
    margin-top: .5rem;
}

.navItem:hover {
    cursor: pointer;
    color: var(--theme-color-5);
}

.navToggle {
    border-color: var(--theme-color-4) !important;
}

@media screen and (max-width: 900px) {

    .portfolioNavbar {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 999;
        border-bottom: none;
        border-top: 1px solid var(--theme-color-5);
    }

    .navItem {
        margin-left: auto;
        margin-right: 1rem;
    }

    .endNavItem {
        display: none;
    }
  }