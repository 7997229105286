.indicatorHolder {
    display: none;
}


@media screen and (max-width: 900px) {
.indicatorHolder {
    width: 100%;
    display: flex;
    position: absolute;
    top: 7rem;
    align-items: center;
    justify-content: center;
    height: 10px;
    max-height: 10px;
}

.indicator, .selectedIndicator {
    position: relative;
    height: 2px;
    background-color: var(--theme-color-5);
    margin: .25rem;
    width: 2rem;
}

.selectedIndicator {
    background-color: var(--theme-color-3);
}
}