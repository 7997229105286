:root {
    --theme-color-1: #003049;
    --theme-color-2: #D62828;
    --theme-color-3: #F77F00;
    --theme-color-4: #FCBF49;
    --theme-color-5: #EAE2B7;
  }

.loaderHolder {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderWrapper {
  width: 160px;
  height: 160px;
}

.loader {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 20px solid var(--theme-color-1);
  border-top-color: var(--theme-color-4);
  border-bottom-color: var(--theme-color-4);
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

.loaderInner {
  border-top-color: var(--theme-color-4);
  border-bottom-color: var(--theme-color-4);
  animation-duration: 2.5s;
}

@keyframes rotate {
  0% {
    transform: scale(1) rotate (360deg);
  }

  50% {
    transform: scale(0.8) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate (360deg);
  }
}
